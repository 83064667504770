import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  HostBinding,
} from '@angular/core';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-sidebar-layout',
  templateUrl: './lefty-sidebar-layout.component.html',
  styleUrls: ['./lefty-sidebar-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftySidebarLayoutComponent {
  changeDetection: ChangeDetectorRef;

  constructor(changeDetector: ChangeDetectorRef) {
    this.changeDetection = changeDetector;
  }

  /// Determine if we should inject
  /// <ng-content select="[left-panel]"></ng-content> inside view
  @Input()
  withLeftPanel = false;

  /// Determine if left panel is visible
  /// [withLeftPanel] must be true
  @Input()
  leftPanelVisible = true;

  @Input()
  leftPanelWidth = 270;

  /// Determine if we should inject
  /// <ng-content select="[right-panel]"></ng-content> inside view
  @Input()
  withRightPanel = false;

  /// Determine if right panel is visible
  /// [withRightPanel] must be true
  @Input()
  rightPanelVisible = true;

  @Input()
  rightPanelWidth = 320;

  @HostBinding('class.has-left-panel-toggle')
  @Input()
  leftPaneltoggle = false;

  sideBarShow = true;

  get contentWidth(): string {
    let width = 0;

    if (this.withLeftPanel && this.leftPanelVisible) {
      width += this.leftPanelWidth;
    }

    if (this.withRightPanel && this.rightPanelVisible) {
      width += this.rightPanelWidth;
    }

    if (this.sideBarShow === false) {
      width = 20;
    }

    if (width === 0) {
      return '100%';
    }

    return `calc(100% - ${width}px)`;
  }

  toggleLeftPanel(): void {
    this.leftPanelVisible = !this.leftPanelVisible;
    this.changeDetection.markForCheck();
  }

  toggleRightPanel(): void {
    this.rightPanelVisible = !this.rightPanelVisible;
    this.changeDetection.markForCheck();
  }

  sideBarToggle(): void {
    this.sideBarShow = !this.sideBarShow;
    this.changeDetection.markForCheck();
  }
}
