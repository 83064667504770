import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Int64Value, Timestamp } from '@bufbuild/protobuf';
import { ConnectError } from '@connectrpc/connect';
import {
  LibrarianAdminClient,
  LibrarianAdminClientProvider,
} from '@frontend2/api';
import {
  Networks,
  capitalize,
  getEnumValues,
  isEqual,
  isNotNil,
  subscriptionToReadable,
} from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  EditSubscriptionReq,
  GrantSubscriptionReq,
  TeamDetails,
  TeamUser,
  UserSubscription,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { SubscriptionType } from '@frontend2/proto/librarian/proto/payments_pb';
import { DialogBase, LeftyValidators, ToastManager } from '@frontend2/ui';
import { Subject } from 'rxjs';

@Component({
  selector: 'edit-subscription-dialog',
  templateUrl: 'edit-subscription-dialog.component.html',
  styleUrls: ['edit-subscription-dialog.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSubscriptionDialogComponent extends DialogBase {
  constructor(
    private toastManager: ToastManager,
    @Inject(LibrarianAdminClientProvider)
    private librarian: LibrarianAdminClient,
  ) {
    super();
    this.disposer.add(this.success$);
  }

  @Output()
  readonly success$ = new Subject<TeamDetails>();

  formLoading = false;
  teamDetails: TeamDetails = new TeamDetails();
  userId = BigInt(0);

  availableSubscriptions =
    getEnumValues<SubscriptionType>(SubscriptionType).splice(1);

  selectedNetworks: Network[] = [
    Network.INSTA,
    Network.YOUTUBE,
    Network.TWEET,
    Network.TIK_TOK,
  ];

  expirationDate: Date | undefined;

  hasAdditional = {
    campaigns: false,
    runningCampaigns: false,
    influencersInCampaign: false,
    reports: false,
    influencersTracked: false,
    affiliates: false,
  };

  hasUnlimited = {
    campaigns: false,
    runningCampaigns: false,
    influencersInCampaign: false,
    reports: false,
    influencersTracked: false,
    affiliates: false,
  };

  readonly formModel: FormGroup = new FormGroup({
    type: new FormControl<SubscriptionType>(SubscriptionType.FREE, [
      Validators.required,
    ]),
    campaigns: new FormControl<number | null>(
      null,
      LeftyValidators.lowerBound(-1),
    ),
    runningCampaigns: new FormControl<number | null>(
      null,
      LeftyValidators.lowerBound(-1),
    ),
    influencersInCampaign: new FormControl<number | null>(
      null,
      LeftyValidators.lowerBound(-1),
    ),
    reports: new FormControl<number | null>(
      null,
      LeftyValidators.lowerBound(-1),
    ),
    influencersTracked: new FormControl<number | null>(
      null,
      LeftyValidators.lowerBound(-1),
    ),
    affiliates: new FormControl<number | null>(
      null,
      LeftyValidators.lowerBound(-1),
    ),
  });

  async openWith(team: TeamUser | TeamDetails): Promise<void> {
    if (team instanceof TeamDetails) {
      this.teamDetails = team;
      this.userId = this.teamDetails.owner?.userId || BigInt(0);
    } else {
      this.teamDetails = await this.librarian.getTeamByIdAPI(
        new Int64Value({ value: team.teamId }),
      );
      this.userId = team.userId;
    }
    this.populateForm(this.teamDetails);
    this.changeDetection.markForCheck();
    this.open();
  }

  populateForm(teamDets: TeamDetails): void {
    const subs = teamDets.subscription;
    if (subs) {
      this.formModel.patchValue({
        type: subs.subscriptionType,
        expires: subs.expires ? subs.expires.toDate() : null,
        campaigns: subs.additionalCampaigns,
        runningCampaigns: subs.additionalRunningCampaigns,
        influencersInCampaign: subs.additionalInfluencersInCampaign,
        reports: subs.additionalReports,
        influencersTracked: subs.additionalInfluencersTracked,
        affiliates: subs.additionalAffiliationInfluencers,
      });
      this.selectedNetworks = subs.allowedNetworks;
      this.expirationDate = subs.expires?.toDate();
      this.hasAdditional = {
        campaigns: subs.additionalCampaigns !== 0,
        runningCampaigns: subs.additionalRunningCampaigns !== 0,
        influencersInCampaign: subs.additionalInfluencersInCampaign !== 0,
        reports: subs.additionalReports !== 0,
        influencersTracked: subs.additionalInfluencersTracked !== 0,
        affiliates: subs.additionalAffiliationInfluencers !== 0,
      };
      this.hasUnlimited = {
        campaigns: subs.additionalCampaigns === -1,
        runningCampaigns: subs.additionalRunningCampaigns === -1,
        influencersInCampaign: subs.additionalInfluencersInCampaign === -1,
        reports: subs.additionalReports === -1,
        influencersTracked: subs.additionalInfluencersTracked === -1,
        affiliates: subs.additionalAffiliationInfluencers === -1,
      };
    }
  }

  networkRenderer(network: Network): string {
    return capitalize(Networks.readable(network));
  }

  subscriptionRenderer(sub: SubscriptionType): string {
    return capitalize(subscriptionToReadable(sub));
  }

  async submit(): Promise<void> {
    if (
      this.formModel.invalid ||
      (!this.canEditSubscription &&
        isEqual(
          this.selectedNetworks,
          this.teamDetails.subscription?.allowedNetworks,
        ))
    ) {
      return;
    }
    this.setState(() => (this.formLoading = true));
    try {
      if (this.teamDetails.subscription?.id !== BigInt(0)) {
        const req = new EditSubscriptionReq({
          subscriptionId: this.teamDetails.subscription?.id,
          subscription: this.userSubscriptionFromForm(),
        });
        await this.librarian.editSubscriptionAPI(req);
        this.toastManager.showSuccess('Subscription edited with success.');
      } else {
        const req = new GrantSubscriptionReq({
          userId: this.userId,
          subscription: this.userSubscriptionFromForm(),
        });
        await this.librarian.grantSubscriptionAPI(req);
        this.toastManager.showSuccess('Subscription granted with success.');
      }
      this.close();
      this.success$.next(this.teamDetails);
    } catch (e: unknown) {
      if (e instanceof ConnectError) {
        this.toastManager.showError(e.message);
        this.changeDetection.markForCheck();
      }
    } finally {
      this.setState(() => (this.formLoading = false));
    }
  }

  private userSubscriptionFromForm(): UserSubscription {
    return new UserSubscription({
      subscriptionType: this.formModel.get('type')?.value,
      additionalReports: parseInt(this.formModel.get('reports')?.value),
      additionalCampaigns: parseInt(this.formModel.get('campaigns')?.value),
      additionalRunningCampaigns: parseInt(
        this.formModel.get('runningCampaigns')?.value,
      ),
      additionalInfluencersInCampaign: parseInt(
        this.formModel.get('influencersInCampaign')?.value,
      ),
      additionalInfluencersTracked: parseInt(
        this.formModel.get('influencersTracked')?.value,
      ),
      additionalAffiliationInfluencers: parseInt(
        this.formModel.get('affiliates')?.value,
      ),
      allowedNetworks: this.selectedNetworks,
      expires: this.expirationDate
        ? Timestamp.fromDate(this.expirationDate)
        : undefined,
      id: this.teamDetails.subscription?.id,
    });
  }

  unlimitedChange(val: boolean, formControlName: string): void {
    if (val) {
      this.formModel.controls[formControlName].setValue(-1);
    }
  }

  additionalChange(val: boolean, formControlName: string): void {
    if (!val) {
      this.formModel.controls[formControlName].setValue(0);
    }
  }

  get canEditSubscription(): boolean {
    return (
      this.teamDetails.id !== BigInt(0) &&
      isNotNil(this.teamDetails.subscription) &&
      this.teamDetails.subscription.isChargebee === false
    );
  }

  get availableNetworks(): Network[] {
    return Networks.supportedAdmin;
  }
}
