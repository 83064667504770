import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '@frontend2/ui';
import { CommonComponentsModule } from '../../common/components/common-components.module';
import { CustomerDetailsRouteComponent } from './customer-details.route';
import { WorkspaceItemComponent } from './workspace-item/workspace-item.component';
import { WorkspaceListComponent } from './workspaces-list/workspaces-list.component';

const routes: Routes = [
  {
    path: '',
    component: CustomerDetailsRouteComponent,
  },
];

@NgModule({
  imports: [
    UiModule,
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    CommonComponentsModule,
  ],
  declarations: [
    CustomerDetailsRouteComponent,
    WorkspaceItemComponent,
    WorkspaceListComponent,
  ],
  exports: [RouterModule],
  providers: [],
})
export class CustomerDetailsModule {}
