import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
} from '@angular/core';
import { LeftyFormat, plural } from '@frontend2/core';
import { Account, TeamUser } from '@frontend2/proto/librarian/proto/admin_pb';
import { LeftyComponent } from '@frontend2/ui';
import { Subject } from 'rxjs';
import { TEAM_PATH } from '../../../constants';

@Component({
  selector: 'workspaces-list',
  templateUrl: 'workspaces-list.component.html',
  styleUrls: ['workspaces-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceListComponent extends LeftyComponent {
  constructor() {
    super();
    this.disposer.add(this.workspaceToCreateOrEdit$);
    this.disposer.add(this.subscriptionToEdit$);
  }

  @Input()
  user: Account = new Account();

  @Output()
  readonly workspaceToCreateOrEdit$ = new Subject<TeamUser | Account>();

  @Output()
  readonly subscriptionToEdit$ = new Subject<TeamUser>();

  get workspaces(): string {
    return (
      LeftyFormat.profiles(this.workspacesCount, { showZero: true }) +
      ' ' +
      plural(this.workspacesCount, {
        one: $localize`Workspace`,
        other: $localize`Workspaces`,
      })
    );
  }

  get workspacesCount(): number {
    return new Set(this.users.filter((item) => item.teamId)).size;
  }

  get users(): TeamUser[] {
    return this.user.users;
  }

  createOrEditWorkspace(user: TeamUser | Account): void {
    this.workspaceToCreateOrEdit$.next(user);
  }

  editSubscription(teamId: TeamUser): void {
    this.subscriptionToEdit$.next(teamId);
  }

  trackByTeamId(index: number, item: TeamUser): bigint {
    return item.teamId;
  }

  userIsGhost(user: TeamUser): boolean {
    return user.userId === BigInt(0);
  }

  userHasTeamId(user: TeamUser): boolean {
    return user.teamId !== BigInt(0);
  }

  teamLink(user: TeamUser): string | undefined {
    return !this.userIsGhost(user)
      ? `/${TEAM_PATH}/${user.teamId}/members`
      : undefined;
  }
}
