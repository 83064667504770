import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import { isGhostCustomer } from '@frontend2/core';
import { Account } from '@frontend2/proto/librarian/proto/admin_pb';
import { LeftyComponent } from '@frontend2/ui';
import { Subject } from 'rxjs';

@Component({
  selector: 'customers-list',
  templateUrl: 'customers-list.component.html',
  styleUrls: ['customers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomersListComponent extends LeftyComponent {
  constructor() {
    super();
    this.disposer.add(this.customerToEdit$);
  }

  @Output()
  readonly customerToEdit$ = new Subject<Account>();

  @Input() customers: Account[] = [];

  useraccountIsGhost(customer: Account): boolean {
    return isGhostCustomer(customer);
  }

  customerLink(account: Account): string | undefined {
    return !this.useraccountIsGhost(account)
      ? './' + account.userAccountId.toString()
      : undefined;
  }

  editCustomer(user: Account): void {
    this.customerToEdit$.next(user);
  }
}
