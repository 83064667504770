import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { formatDate, isGhostCustomer, isNotEmptyString } from '@frontend2/core';
import { Account } from '@frontend2/proto/librarian/proto/admin_pb';
import { LeftyComponent } from '@frontend2/ui';

@Component({
  selector: 'customer-item',
  templateUrl: 'customer-item.component.html',
  styleUrls: ['customer-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerItemComponent extends LeftyComponent {
  @Input()
  customer = new Account();

  @Input()
  hideDetails = false;

  loading = false;

  useraccountIsGhost(customer: Account): boolean {
    return isGhostCustomer(customer);
  }

  stayInPage(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  get email(): string {
    return `${this.customer.userAccountId} - ${this.customer.email}`;
  }

  get name(): string {
    return this.customer.firstName + ' ' + this.customer.lastName;
  }

  get created(): string {
    return formatDate(this.customer.created?.toDate() ?? '');
  }

  get company(): string {
    return isNotEmptyString(this.customer.company)
      ? this.customer.company
      : '-';
  }

  get dashboardAccess(): boolean {
    return this.customer.dashboardAccess;
  }

  get ssoProviderName(): string {
    return this.customer.ssoProviderName || 'No';
  }
}
