// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/affiliation_promo_codes.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, DoubleValue, Int32Value, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { ProductPb, ProductVariantPb } from "./products_pb";
import { Program } from "./affiliation_pb";
import { CreatorCardSnippet, CreatorTiniestCard } from "./creators_pb";
import { EcommerceStorePb, EcommerceStoreWithInfoPb } from "./ecommerce_stores_pb";
import { BasicPagination, SortDirection } from "../../common/proto/common_pb";

/**
 * @generated from enum librarian.proto.AffiliationPromoCodeDiscountType
 */
export enum AffiliationPromoCodeDiscountType {
  /**
   * @generated from enum value: AFFILIATION_PROMO_CODE_DISCOUNT_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: AFFILIATION_PROMO_CODE_DISCOUNT_TYPE_FIXED_AMOUNT = 1;
   */
  FIXED_AMOUNT = 1,

  /**
   * @generated from enum value: AFFILIATION_PROMO_CODE_DISCOUNT_TYPE_PERCENTAGE = 2;
   */
  PERCENTAGE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AffiliationPromoCodeDiscountType)
proto3.util.setEnumType(AffiliationPromoCodeDiscountType, "librarian.proto.AffiliationPromoCodeDiscountType", [
  { no: 0, name: "AFFILIATION_PROMO_CODE_DISCOUNT_TYPE_UNKNOWN" },
  { no: 1, name: "AFFILIATION_PROMO_CODE_DISCOUNT_TYPE_FIXED_AMOUNT" },
  { no: 2, name: "AFFILIATION_PROMO_CODE_DISCOUNT_TYPE_PERCENTAGE" },
]);

/**
 * @generated from enum librarian.proto.AffiliationPromoCodeMinRequirementsType
 */
export enum AffiliationPromoCodeMinRequirementsType {
  /**
   * @generated from enum value: AFFILIATION_PROMO_CODE_MIN_REQUIREMENTS_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: AFFILIATION_PROMO_CODE_MIN_REQUIREMENTS_TYPE_PURCHASE_AMOUNT = 1;
   */
  PURCHASE_AMOUNT = 1,

  /**
   * @generated from enum value: AFFILIATION_PROMO_CODE_MIN_REQUIREMENTS_TYPE_QUANTITY_OF_ITEMS = 2;
   */
  QUANTITY_OF_ITEMS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AffiliationPromoCodeMinRequirementsType)
proto3.util.setEnumType(AffiliationPromoCodeMinRequirementsType, "librarian.proto.AffiliationPromoCodeMinRequirementsType", [
  { no: 0, name: "AFFILIATION_PROMO_CODE_MIN_REQUIREMENTS_TYPE_UNKNOWN" },
  { no: 1, name: "AFFILIATION_PROMO_CODE_MIN_REQUIREMENTS_TYPE_PURCHASE_AMOUNT" },
  { no: 2, name: "AFFILIATION_PROMO_CODE_MIN_REQUIREMENTS_TYPE_QUANTITY_OF_ITEMS" },
]);

/**
 * @generated from enum librarian.proto.AffiliationPromoCodeStatus
 */
export enum AffiliationPromoCodeStatus {
  /**
   * @generated from enum value: AFFILIATION_PROMO_CODE_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: AFFILIATION_PROMO_CODE_STATUS_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: AFFILIATION_PROMO_CODE_STATUS_INACTIVE = 2;
   */
  INACTIVE = 2,

  /**
   * @generated from enum value: AFFILIATION_PROMO_CODE_STATUS_DELETED = 3;
   */
  DELETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AffiliationPromoCodeStatus)
proto3.util.setEnumType(AffiliationPromoCodeStatus, "librarian.proto.AffiliationPromoCodeStatus", [
  { no: 0, name: "AFFILIATION_PROMO_CODE_STATUS_UNKNOWN" },
  { no: 1, name: "AFFILIATION_PROMO_CODE_STATUS_ACTIVE" },
  { no: 2, name: "AFFILIATION_PROMO_CODE_STATUS_INACTIVE" },
  { no: 3, name: "AFFILIATION_PROMO_CODE_STATUS_DELETED" },
]);

/**
 * @generated from enum librarian.proto.PromoCodesSort
 */
export enum PromoCodesSort {
  /**
   * @generated from enum value: PROMO_CODES_SORT_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: PROMO_CODES_SORT_CODE = 1;
   */
  CODE = 1,

  /**
   * @generated from enum value: PROMO_CODES_SORT_INFLUENCER_NAME = 2;
   */
  INFLUENCER_NAME = 2,

  /**
   * @generated from enum value: PROMO_CODES_SORT_CREATION_DATE = 3;
   */
  CREATION_DATE = 3,

  /**
   * @generated from enum value: PROMO_CODES_SORT_STORE_NAME = 4;
   */
  STORE_NAME = 4,

  /**
   * @generated from enum value: PROMO_CODES_SORT_PROGRAM_NAME = 5;
   */
  PROGRAM_NAME = 5,

  /**
   * @generated from enum value: PROMO_CODES_SORT_CAMPAIGN_NAME = 6;
   */
  CAMPAIGN_NAME = 6,

  /**
   * @generated from enum value: PROMO_CODES_SORT_CONVERSIONS = 7;
   */
  CONVERSIONS = 7,

  /**
   * @generated from enum value: PROMO_CODES_SORT_SALES = 8;
   */
  SALES = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(PromoCodesSort)
proto3.util.setEnumType(PromoCodesSort, "librarian.proto.PromoCodesSort", [
  { no: 0, name: "PROMO_CODES_SORT_UNKNOWN" },
  { no: 1, name: "PROMO_CODES_SORT_CODE" },
  { no: 2, name: "PROMO_CODES_SORT_INFLUENCER_NAME" },
  { no: 3, name: "PROMO_CODES_SORT_CREATION_DATE" },
  { no: 4, name: "PROMO_CODES_SORT_STORE_NAME" },
  { no: 5, name: "PROMO_CODES_SORT_PROGRAM_NAME" },
  { no: 6, name: "PROMO_CODES_SORT_CAMPAIGN_NAME" },
  { no: 7, name: "PROMO_CODES_SORT_CONVERSIONS" },
  { no: 8, name: "PROMO_CODES_SORT_SALES" },
]);

/**
 * @generated from message librarian.proto.CreatePromoCodesRequest
 */
export class CreatePromoCodesRequest extends Message<CreatePromoCodesRequest> {
  /**
   * @generated from field: repeated string influencer_ids = 1;
   */
  influencerIds: string[] = [];

  /**
   * @generated from field: int64 ecommerce_store_id = 2;
   */
  ecommerceStoreId = protoInt64.zero;

  /**
   * @generated from field: int64 affiliation_program_id = 3;
   */
  affiliationProgramId = protoInt64.zero;

  /**
   * @generated from oneof librarian.proto.CreatePromoCodesRequest.discount
   */
  discount: {
    /**
     * @generated from field: int32 percentage = 4;
     */
    value: number;
    case: "percentage";
  } | {
    /**
     * @generated from field: double fixed_amount = 5;
     */
    value: number;
    case: "fixedAmount";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestEntitledProducts entitled_products = 6 [deprecated = true];
   * @deprecated
   */
  entitledProducts?: CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts;

  /**
   * @generated from field: librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestEntitledProductVariants entitled_product_variants = 17;
   */
  entitledProductVariants?: CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants;

  /**
   * @generated from oneof librarian.proto.CreatePromoCodesRequest.code_name
   */
  codeName: {
    /**
     * @generated from field: string auto_generated_influencer_name_suffix = 7;
     */
    value: string;
    case: "autoGeneratedInfluencerNameSuffix";
  } | {
    /**
     * @generated from field: string auto_generated_code_suffix = 8;
     */
    value: string;
    case: "autoGeneratedCodeSuffix";
  } | {
    /**
     * @generated from field: librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestManualInput manual_input = 9;
     */
    value: CreatePromoCodesRequest_CreatePromoCodesRequestManualInput;
    case: "manualInput";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.CreatePromoCodesRequest.min_requirements
   */
  minRequirements: {
    /**
     * @generated from field: double min_purchase_amount = 10;
     */
    value: number;
    case: "minPurchaseAmount";
  } | {
    /**
     * @generated from field: int32 min_quantity_of_items = 11;
     */
    value: number;
    case: "minQuantityOfItems";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: google.protobuf.Timestamp expiration_date = 12;
   */
  expirationDate?: Timestamp;

  /**
   * @generated from field: bool limit_to_one_use_per_customer = 13;
   */
  limitToOneUsePerCustomer = false;

  /**
   * @generated from field: google.protobuf.Int32Value total_uses_limit = 14;
   */
  totalUsesLimit?: number;

  /**
   * @generated from field: google.protobuf.Int64Value campaign_id = 15;
   */
  campaignId?: bigint;

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 16;
   */
  startDate?: Timestamp;

  constructor(data?: PartialMessage<CreatePromoCodesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatePromoCodesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "ecommerce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "affiliation_program_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "discount" },
    { no: 5, name: "fixed_amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "discount" },
    { no: 6, name: "entitled_products", kind: "message", T: CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts },
    { no: 17, name: "entitled_product_variants", kind: "message", T: CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants },
    { no: 7, name: "auto_generated_influencer_name_suffix", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "code_name" },
    { no: 8, name: "auto_generated_code_suffix", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "code_name" },
    { no: 9, name: "manual_input", kind: "message", T: CreatePromoCodesRequest_CreatePromoCodesRequestManualInput, oneof: "code_name" },
    { no: 10, name: "min_purchase_amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "min_requirements" },
    { no: 11, name: "min_quantity_of_items", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "min_requirements" },
    { no: 12, name: "expiration_date", kind: "message", T: Timestamp },
    { no: 13, name: "limit_to_one_use_per_customer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "total_uses_limit", kind: "message", T: Int32Value },
    { no: 15, name: "campaign_id", kind: "message", T: Int64Value },
    { no: 16, name: "start_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePromoCodesRequest {
    return new CreatePromoCodesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePromoCodesRequest {
    return new CreatePromoCodesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePromoCodesRequest {
    return new CreatePromoCodesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePromoCodesRequest | PlainMessage<CreatePromoCodesRequest> | undefined, b: CreatePromoCodesRequest | PlainMessage<CreatePromoCodesRequest> | undefined): boolean {
    return proto3.util.equals(CreatePromoCodesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestEntitledProducts
 */
export class CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts extends Message<CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts> {
  /**
   * @generated from field: repeated int64 products_ids = 1;
   */
  productsIds: bigint[] = [];

  constructor(data?: PartialMessage<CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestEntitledProducts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts | PlainMessage<CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts> | undefined, b: CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts | PlainMessage<CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts> | undefined): boolean {
    return proto3.util.equals(CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProducts, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestEntitledProductVariants
 */
export class CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants extends Message<CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants> {
  /**
   * @generated from field: repeated int64 product_variants_ids = 1;
   */
  productVariantsIds: bigint[] = [];

  constructor(data?: PartialMessage<CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestEntitledProductVariants";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_variants_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants | PlainMessage<CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants> | undefined, b: CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants | PlainMessage<CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants> | undefined): boolean {
    return proto3.util.equals(CreatePromoCodesRequest_CreatePromoCodesRequestEntitledProductVariants, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestManualInput
 */
export class CreatePromoCodesRequest_CreatePromoCodesRequestManualInput extends Message<CreatePromoCodesRequest_CreatePromoCodesRequestManualInput> {
  /**
   * @generated from field: repeated librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestManualInput.CreatePromoCodesRequestInfluencerCode influencer_codes = 1;
   */
  influencerCodes: CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode[] = [];

  constructor(data?: PartialMessage<CreatePromoCodesRequest_CreatePromoCodesRequestManualInput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestManualInput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_codes", kind: "message", T: CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestManualInput {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestManualInput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestManualInput {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestManualInput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestManualInput {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestManualInput().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePromoCodesRequest_CreatePromoCodesRequestManualInput | PlainMessage<CreatePromoCodesRequest_CreatePromoCodesRequestManualInput> | undefined, b: CreatePromoCodesRequest_CreatePromoCodesRequestManualInput | PlainMessage<CreatePromoCodesRequest_CreatePromoCodesRequestManualInput> | undefined): boolean {
    return proto3.util.equals(CreatePromoCodesRequest_CreatePromoCodesRequestManualInput, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestManualInput.CreatePromoCodesRequestInfluencerCode
 */
export class CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode extends Message<CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode> {
  /**
   * @generated from field: string influencer_id = 1;
   */
  influencerId = "";

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  constructor(data?: PartialMessage<CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatePromoCodesRequest.CreatePromoCodesRequestManualInput.CreatePromoCodesRequestInfluencerCode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode {
    return new CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode | PlainMessage<CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode> | undefined, b: CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode | PlainMessage<CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode> | undefined): boolean {
    return proto3.util.equals(CreatePromoCodesRequest_CreatePromoCodesRequestManualInput_CreatePromoCodesRequestInfluencerCode, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationPromoCodeMinRequirementsPb
 */
export class AffiliationPromoCodeMinRequirementsPb extends Message<AffiliationPromoCodeMinRequirementsPb> {
  /**
   * @generated from field: librarian.proto.AffiliationPromoCodeMinRequirementsType type = 1;
   */
  type = AffiliationPromoCodeMinRequirementsType.UNKNOWN;

  /**
   * @generated from field: double amount = 2;
   */
  amount = 0;

  constructor(data?: PartialMessage<AffiliationPromoCodeMinRequirementsPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationPromoCodeMinRequirementsPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(AffiliationPromoCodeMinRequirementsType) },
    { no: 2, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationPromoCodeMinRequirementsPb {
    return new AffiliationPromoCodeMinRequirementsPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationPromoCodeMinRequirementsPb {
    return new AffiliationPromoCodeMinRequirementsPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationPromoCodeMinRequirementsPb {
    return new AffiliationPromoCodeMinRequirementsPb().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationPromoCodeMinRequirementsPb | PlainMessage<AffiliationPromoCodeMinRequirementsPb> | undefined, b: AffiliationPromoCodeMinRequirementsPb | PlainMessage<AffiliationPromoCodeMinRequirementsPb> | undefined): boolean {
    return proto3.util.equals(AffiliationPromoCodeMinRequirementsPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationPromoCodePb
 */
export class AffiliationPromoCodePb extends Message<AffiliationPromoCodePb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: bool active = 2;
   */
  active = false;

  /**
   * @generated from field: string code = 3;
   */
  code = "";

  /**
   * @generated from field: double discount_value = 4;
   */
  discountValue = 0;

  /**
   * @generated from field: librarian.proto.AffiliationPromoCodeDiscountType discount_type = 5;
   */
  discountType = AffiliationPromoCodeDiscountType.UNKNOWN;

  /**
   * @generated from field: google.protobuf.Timestamp expiration_date = 6;
   */
  expirationDate?: Timestamp;

  /**
   * @generated from field: string external_discount_code_id = 7;
   */
  externalDiscountCodeId = "";

  /**
   * @generated from field: bool one_use_per_customer = 8;
   */
  oneUsePerCustomer = false;

  /**
   * @generated from field: bool apply_to_any_product = 9;
   */
  applyToAnyProduct = false;

  /**
   * @generated from field: repeated librarian.proto.ProductPb entitled_products = 10 [deprecated = true];
   * @deprecated
   */
  entitledProducts: ProductPb[] = [];

  /**
   * @generated from field: repeated librarian.proto.AffiliationPromoCodeProductVariant entitled_product_variants = 21;
   */
  entitledProductVariants: AffiliationPromoCodeProductVariant[] = [];

  /**
   * @generated from field: google.protobuf.Int64Value uses_limit = 11;
   */
  usesLimit?: bigint;

  /**
   * @generated from field: librarian.proto.AffiliationPromoCodeMinRequirementsPb min_requirements = 12;
   */
  minRequirements?: AffiliationPromoCodeMinRequirementsPb;

  /**
   * @generated from field: librarian.proto.Program affiliation_program = 13;
   */
  affiliationProgram?: Program;

  /**
   * @generated from field: librarian.proto.CreatorTiniestCard influencer = 14;
   */
  influencer?: CreatorTiniestCard;

  /**
   * @generated from field: librarian.proto.EcommerceStorePb ecommerce_store = 15;
   */
  ecommerceStore?: EcommerceStorePb;

  /**
   * @generated from field: librarian.proto.PromoCodeCampaign campaign = 16;
   */
  campaign?: PromoCodeCampaign;

  /**
   * @generated from field: librarian.proto.AffiliationPromoCodeStatus status = 17;
   */
  status = AffiliationPromoCodeStatus.UNKNOWN;

  /**
   * @generated from field: google.protobuf.Timestamp created = 18;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 19;
   */
  startDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.StringValue external_url = 20;
   */
  externalUrl?: string;

  constructor(data?: PartialMessage<AffiliationPromoCodePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationPromoCodePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "discount_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "discount_type", kind: "enum", T: proto3.getEnumType(AffiliationPromoCodeDiscountType) },
    { no: 6, name: "expiration_date", kind: "message", T: Timestamp },
    { no: 7, name: "external_discount_code_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "one_use_per_customer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "apply_to_any_product", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "entitled_products", kind: "message", T: ProductPb, repeated: true },
    { no: 21, name: "entitled_product_variants", kind: "message", T: AffiliationPromoCodeProductVariant, repeated: true },
    { no: 11, name: "uses_limit", kind: "message", T: Int64Value },
    { no: 12, name: "min_requirements", kind: "message", T: AffiliationPromoCodeMinRequirementsPb },
    { no: 13, name: "affiliation_program", kind: "message", T: Program },
    { no: 14, name: "influencer", kind: "message", T: CreatorTiniestCard },
    { no: 15, name: "ecommerce_store", kind: "message", T: EcommerceStorePb },
    { no: 16, name: "campaign", kind: "message", T: PromoCodeCampaign },
    { no: 17, name: "status", kind: "enum", T: proto3.getEnumType(AffiliationPromoCodeStatus) },
    { no: 18, name: "created", kind: "message", T: Timestamp },
    { no: 19, name: "start_date", kind: "message", T: Timestamp },
    { no: 20, name: "external_url", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationPromoCodePb {
    return new AffiliationPromoCodePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationPromoCodePb {
    return new AffiliationPromoCodePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationPromoCodePb {
    return new AffiliationPromoCodePb().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationPromoCodePb | PlainMessage<AffiliationPromoCodePb> | undefined, b: AffiliationPromoCodePb | PlainMessage<AffiliationPromoCodePb> | undefined): boolean {
    return proto3.util.equals(AffiliationPromoCodePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationPromoCodeProductVariant
 */
export class AffiliationPromoCodeProductVariant extends Message<AffiliationPromoCodeProductVariant> {
  /**
   * @generated from field: librarian.proto.ProductVariantPb product_variant = 1 [deprecated = true];
   * @deprecated
   */
  productVariant?: ProductVariantPb;

  /**
   * @generated from field: int64 product_id = 2;
   */
  productId = protoInt64.zero;

  /**
   * @generated from field: repeated librarian.proto.ProductVariantPb product_variants = 3;
   */
  productVariants: ProductVariantPb[] = [];

  /**
   * @generated from field: google.protobuf.StringValue asset_url = 4;
   */
  assetUrl?: string;

  constructor(data?: PartialMessage<AffiliationPromoCodeProductVariant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationPromoCodeProductVariant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_variant", kind: "message", T: ProductVariantPb },
    { no: 2, name: "product_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "product_variants", kind: "message", T: ProductVariantPb, repeated: true },
    { no: 4, name: "asset_url", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationPromoCodeProductVariant {
    return new AffiliationPromoCodeProductVariant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationPromoCodeProductVariant {
    return new AffiliationPromoCodeProductVariant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationPromoCodeProductVariant {
    return new AffiliationPromoCodeProductVariant().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationPromoCodeProductVariant | PlainMessage<AffiliationPromoCodeProductVariant> | undefined, b: AffiliationPromoCodeProductVariant | PlainMessage<AffiliationPromoCodeProductVariant> | undefined): boolean {
    return proto3.util.equals(AffiliationPromoCodeProductVariant, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationPromoCodeWithInfoPb
 */
export class AffiliationPromoCodeWithInfoPb extends Message<AffiliationPromoCodeWithInfoPb> {
  /**
   * @generated from field: librarian.proto.AffiliationPromoCodePb promo_code = 1;
   */
  promoCode?: AffiliationPromoCodePb;

  /**
   * @generated from field: int64 conversions = 2;
   */
  conversions = protoInt64.zero;

  /**
   * @generated from field: double sales = 3;
   */
  sales = 0;

  /**
   * @generated from field: librarian.proto.EcommerceStoreWithInfoPb ecommerce_store_with_info = 4;
   */
  ecommerceStoreWithInfo?: EcommerceStoreWithInfoPb;

  constructor(data?: PartialMessage<AffiliationPromoCodeWithInfoPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationPromoCodeWithInfoPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "promo_code", kind: "message", T: AffiliationPromoCodePb },
    { no: 2, name: "conversions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "sales", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "ecommerce_store_with_info", kind: "message", T: EcommerceStoreWithInfoPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationPromoCodeWithInfoPb {
    return new AffiliationPromoCodeWithInfoPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationPromoCodeWithInfoPb {
    return new AffiliationPromoCodeWithInfoPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationPromoCodeWithInfoPb {
    return new AffiliationPromoCodeWithInfoPb().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationPromoCodeWithInfoPb | PlainMessage<AffiliationPromoCodeWithInfoPb> | undefined, b: AffiliationPromoCodeWithInfoPb | PlainMessage<AffiliationPromoCodeWithInfoPb> | undefined): boolean {
    return proto3.util.equals(AffiliationPromoCodeWithInfoPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.PromoCodeCampaign
 */
export class PromoCodeCampaign extends Message<PromoCodeCampaign> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<PromoCodeCampaign>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PromoCodeCampaign";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PromoCodeCampaign {
    return new PromoCodeCampaign().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PromoCodeCampaign {
    return new PromoCodeCampaign().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PromoCodeCampaign {
    return new PromoCodeCampaign().fromJsonString(jsonString, options);
  }

  static equals(a: PromoCodeCampaign | PlainMessage<PromoCodeCampaign> | undefined, b: PromoCodeCampaign | PlainMessage<PromoCodeCampaign> | undefined): boolean {
    return proto3.util.equals(PromoCodeCampaign, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatePromoCodesTask
 */
export class CreatePromoCodesTask extends Message<CreatePromoCodesTask> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CreatePromoCodesRequest request = 2;
   */
  request?: CreatePromoCodesRequest;

  constructor(data?: PartialMessage<CreatePromoCodesTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatePromoCodesTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "request", kind: "message", T: CreatePromoCodesRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePromoCodesTask {
    return new CreatePromoCodesTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePromoCodesTask {
    return new CreatePromoCodesTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePromoCodesTask {
    return new CreatePromoCodesTask().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePromoCodesTask | PlainMessage<CreatePromoCodesTask> | undefined, b: CreatePromoCodesTask | PlainMessage<CreatePromoCodesTask> | undefined): boolean {
    return proto3.util.equals(CreatePromoCodesTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatePromoCodesTaskMetaData
 */
export class CreatePromoCodesTaskMetaData extends Message<CreatePromoCodesTaskMetaData> {
  /**
   * A map that is used to know if we should retry creating the promocode for the influencerId or not.
   *
   * @generated from field: map<string, bool> retry_by_influencer_id = 1;
   */
  retryByInfluencerId: { [key: string]: boolean } = {};

  /**
   * @generated from field: repeated librarian.proto.AffiliationPromoCodePb created_promo_codes = 2;
   */
  createdPromoCodes: AffiliationPromoCodePb[] = [];

  constructor(data?: PartialMessage<CreatePromoCodesTaskMetaData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatePromoCodesTaskMetaData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "retry_by_influencer_id", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 8 /* ScalarType.BOOL */} },
    { no: 2, name: "created_promo_codes", kind: "message", T: AffiliationPromoCodePb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePromoCodesTaskMetaData {
    return new CreatePromoCodesTaskMetaData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePromoCodesTaskMetaData {
    return new CreatePromoCodesTaskMetaData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePromoCodesTaskMetaData {
    return new CreatePromoCodesTaskMetaData().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePromoCodesTaskMetaData | PlainMessage<CreatePromoCodesTaskMetaData> | undefined, b: CreatePromoCodesTaskMetaData | PlainMessage<CreatePromoCodesTaskMetaData> | undefined): boolean {
    return proto3.util.equals(CreatePromoCodesTaskMetaData, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatePromoCodesTaskOutput
 */
export class CreatePromoCodesTaskOutput extends Message<CreatePromoCodesTaskOutput> {
  /**
   * @generated from field: repeated librarian.proto.AffiliationPromoCodePb promo_codes = 1;
   */
  promoCodes: AffiliationPromoCodePb[] = [];

  constructor(data?: PartialMessage<CreatePromoCodesTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatePromoCodesTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "promo_codes", kind: "message", T: AffiliationPromoCodePb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePromoCodesTaskOutput {
    return new CreatePromoCodesTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePromoCodesTaskOutput {
    return new CreatePromoCodesTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePromoCodesTaskOutput {
    return new CreatePromoCodesTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePromoCodesTaskOutput | PlainMessage<CreatePromoCodesTaskOutput> | undefined, b: CreatePromoCodesTaskOutput | PlainMessage<CreatePromoCodesTaskOutput> | undefined): boolean {
    return proto3.util.equals(CreatePromoCodesTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatePromoCodesResponse
 */
export class CreatePromoCodesResponse extends Message<CreatePromoCodesResponse> {
  /**
   * @generated from field: string task_handle = 1;
   */
  taskHandle = "";

  constructor(data?: PartialMessage<CreatePromoCodesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatePromoCodesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePromoCodesResponse {
    return new CreatePromoCodesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePromoCodesResponse {
    return new CreatePromoCodesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePromoCodesResponse {
    return new CreatePromoCodesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePromoCodesResponse | PlainMessage<CreatePromoCodesResponse> | undefined, b: CreatePromoCodesResponse | PlainMessage<CreatePromoCodesResponse> | undefined): boolean {
    return proto3.util.equals(CreatePromoCodesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ShopifyDiscountCodeCreationPb
 */
export class ShopifyDiscountCodeCreationPb extends Message<ShopifyDiscountCodeCreationPb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: int64 price_rule_id = 2;
   */
  priceRuleId = protoInt64.zero;

  /**
   * Key is is the discount code created for the influencer, value is the influencer id
   *
   * @generated from field: map<string, string> influencer_id_by_discount_code = 3;
   */
  influencerIdByDiscountCode: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ShopifyDiscountCodeCreationPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ShopifyDiscountCodeCreationPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "price_rule_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "influencer_id_by_discount_code", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShopifyDiscountCodeCreationPb {
    return new ShopifyDiscountCodeCreationPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShopifyDiscountCodeCreationPb {
    return new ShopifyDiscountCodeCreationPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShopifyDiscountCodeCreationPb {
    return new ShopifyDiscountCodeCreationPb().fromJsonString(jsonString, options);
  }

  static equals(a: ShopifyDiscountCodeCreationPb | PlainMessage<ShopifyDiscountCodeCreationPb> | undefined, b: ShopifyDiscountCodeCreationPb | PlainMessage<ShopifyDiscountCodeCreationPb> | undefined): boolean {
    return proto3.util.equals(ShopifyDiscountCodeCreationPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetPromoCodesRequest
 */
export class GetPromoCodesRequest extends Message<GetPromoCodesRequest> {
  /**
   * @generated from field: librarian.proto.PromoCodesFilter filter = 1;
   */
  filter?: PromoCodesFilter;

  /**
   * @generated from field: librarian.proto.PromoCodesSort sort = 2;
   */
  sort = PromoCodesSort.UNKNOWN;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 3;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetPromoCodesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetPromoCodesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: PromoCodesFilter },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(PromoCodesSort) },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPromoCodesRequest {
    return new GetPromoCodesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPromoCodesRequest {
    return new GetPromoCodesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPromoCodesRequest {
    return new GetPromoCodesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPromoCodesRequest | PlainMessage<GetPromoCodesRequest> | undefined, b: GetPromoCodesRequest | PlainMessage<GetPromoCodesRequest> | undefined): boolean {
    return proto3.util.equals(GetPromoCodesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.PromoCodesFilter
 */
export class PromoCodesFilter extends Message<PromoCodesFilter> {
  /**
   * @generated from field: repeated string influencer_ids = 1;
   */
  influencerIds: string[] = [];

  /**
   * @generated from field: repeated int64 campaign_ids = 2;
   */
  campaignIds: bigint[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 3;
   */
  startDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 4;
   */
  endDate?: Timestamp;

  /**
   * @generated from field: repeated int64 store_ids = 5;
   */
  storeIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 program_ids = 6;
   */
  programIds: bigint[] = [];

  /**
   * @generated from field: librarian.proto.AffiliationPromoCodeStatus status = 7;
   */
  status = AffiliationPromoCodeStatus.UNKNOWN;

  /**
   * @generated from field: google.protobuf.StringValue influencer_name = 8;
   */
  influencerName?: string;

  constructor(data?: PartialMessage<PromoCodesFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PromoCodesFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "campaign_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 3, name: "start_date", kind: "message", T: Timestamp },
    { no: 4, name: "end_date", kind: "message", T: Timestamp },
    { no: 5, name: "store_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 6, name: "program_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(AffiliationPromoCodeStatus) },
    { no: 8, name: "influencer_name", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PromoCodesFilter {
    return new PromoCodesFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PromoCodesFilter {
    return new PromoCodesFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PromoCodesFilter {
    return new PromoCodesFilter().fromJsonString(jsonString, options);
  }

  static equals(a: PromoCodesFilter | PlainMessage<PromoCodesFilter> | undefined, b: PromoCodesFilter | PlainMessage<PromoCodesFilter> | undefined): boolean {
    return proto3.util.equals(PromoCodesFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetPromoCodesResponse
 */
export class GetPromoCodesResponse extends Message<GetPromoCodesResponse> {
  /**
   * @generated from field: repeated librarian.proto.AffiliationPromoCodeWithInfoPb promo_codes = 1;
   */
  promoCodes: AffiliationPromoCodeWithInfoPb[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetPromoCodesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetPromoCodesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "promo_codes", kind: "message", T: AffiliationPromoCodeWithInfoPb, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPromoCodesResponse {
    return new GetPromoCodesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPromoCodesResponse {
    return new GetPromoCodesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPromoCodesResponse {
    return new GetPromoCodesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPromoCodesResponse | PlainMessage<GetPromoCodesResponse> | undefined, b: GetPromoCodesResponse | PlainMessage<GetPromoCodesResponse> | undefined): boolean {
    return proto3.util.equals(GetPromoCodesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetPromoCodeDetailsResponse
 */
export class GetPromoCodeDetailsResponse extends Message<GetPromoCodeDetailsResponse> {
  /**
   * @generated from field: librarian.proto.AffiliationPromoCodeWithInfoPb promo_code = 1;
   */
  promoCode?: AffiliationPromoCodeWithInfoPb;

  constructor(data?: PartialMessage<GetPromoCodeDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetPromoCodeDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "promo_code", kind: "message", T: AffiliationPromoCodeWithInfoPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPromoCodeDetailsResponse {
    return new GetPromoCodeDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPromoCodeDetailsResponse {
    return new GetPromoCodeDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPromoCodeDetailsResponse {
    return new GetPromoCodeDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPromoCodeDetailsResponse | PlainMessage<GetPromoCodeDetailsResponse> | undefined, b: GetPromoCodeDetailsResponse | PlainMessage<GetPromoCodeDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetPromoCodeDetailsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdatePromoCodeRequest
 */
export class UpdatePromoCodeRequest extends Message<UpdatePromoCodeRequest> {
  /**
   * TODO(aly) remove when frontend is migrated and stopped using this field
   *
   * @generated from field: int64 promo_code_id = 1 [deprecated = true];
   * @deprecated
   */
  promoCodeId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.AffiliationPromoCodeStatus status = 2;
   */
  status = AffiliationPromoCodeStatus.UNKNOWN;

  /**
   * @generated from field: repeated int64 promo_codes_ids = 3;
   */
  promoCodesIds: bigint[] = [];

  constructor(data?: PartialMessage<UpdatePromoCodeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdatePromoCodeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "promo_code_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(AffiliationPromoCodeStatus) },
    { no: 3, name: "promo_codes_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePromoCodeRequest {
    return new UpdatePromoCodeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePromoCodeRequest {
    return new UpdatePromoCodeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePromoCodeRequest {
    return new UpdatePromoCodeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePromoCodeRequest | PlainMessage<UpdatePromoCodeRequest> | undefined, b: UpdatePromoCodeRequest | PlainMessage<UpdatePromoCodeRequest> | undefined): boolean {
    return proto3.util.equals(UpdatePromoCodeRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdatePromoCodeResponse
 */
export class UpdatePromoCodeResponse extends Message<UpdatePromoCodeResponse> {
  /**
   * @generated from field: string task_handle = 1;
   */
  taskHandle = "";

  constructor(data?: PartialMessage<UpdatePromoCodeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdatePromoCodeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePromoCodeResponse {
    return new UpdatePromoCodeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePromoCodeResponse {
    return new UpdatePromoCodeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePromoCodeResponse {
    return new UpdatePromoCodeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePromoCodeResponse | PlainMessage<UpdatePromoCodeResponse> | undefined, b: UpdatePromoCodeResponse | PlainMessage<UpdatePromoCodeResponse> | undefined): boolean {
    return proto3.util.equals(UpdatePromoCodeResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdatePromoCodesTask
 */
export class UpdatePromoCodesTask extends Message<UpdatePromoCodesTask> {
  /**
   * @generated from field: repeated librarian.proto.UpdatePromoCodesTask.UpdatePromoCodesTaskJob jobs = 1;
   */
  jobs: UpdatePromoCodesTask_UpdatePromoCodesTaskJob[] = [];

  constructor(data?: PartialMessage<UpdatePromoCodesTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdatePromoCodesTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "jobs", kind: "message", T: UpdatePromoCodesTask_UpdatePromoCodesTaskJob, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePromoCodesTask {
    return new UpdatePromoCodesTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePromoCodesTask {
    return new UpdatePromoCodesTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePromoCodesTask {
    return new UpdatePromoCodesTask().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePromoCodesTask | PlainMessage<UpdatePromoCodesTask> | undefined, b: UpdatePromoCodesTask | PlainMessage<UpdatePromoCodesTask> | undefined): boolean {
    return proto3.util.equals(UpdatePromoCodesTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdatePromoCodesTask.UpdatePromoCodesTaskJob
 */
export class UpdatePromoCodesTask_UpdatePromoCodesTaskJob extends Message<UpdatePromoCodesTask_UpdatePromoCodesTaskJob> {
  /**
   * @generated from field: string job_id = 1;
   */
  jobId = "";

  /**
   * @generated from field: string store_domain = 2;
   */
  storeDomain = "";

  /**
   * List of external promo code ids that are getting updated in this job
   *
   * @generated from field: repeated string external_discount_code_id = 7;
   */
  externalDiscountCodeId: string[] = [];

  constructor(data?: PartialMessage<UpdatePromoCodesTask_UpdatePromoCodesTaskJob>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdatePromoCodesTask.UpdatePromoCodesTaskJob";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "store_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "external_discount_code_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePromoCodesTask_UpdatePromoCodesTaskJob {
    return new UpdatePromoCodesTask_UpdatePromoCodesTaskJob().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePromoCodesTask_UpdatePromoCodesTaskJob {
    return new UpdatePromoCodesTask_UpdatePromoCodesTaskJob().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePromoCodesTask_UpdatePromoCodesTaskJob {
    return new UpdatePromoCodesTask_UpdatePromoCodesTaskJob().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePromoCodesTask_UpdatePromoCodesTaskJob | PlainMessage<UpdatePromoCodesTask_UpdatePromoCodesTaskJob> | undefined, b: UpdatePromoCodesTask_UpdatePromoCodesTaskJob | PlainMessage<UpdatePromoCodesTask_UpdatePromoCodesTaskJob> | undefined): boolean {
    return proto3.util.equals(UpdatePromoCodesTask_UpdatePromoCodesTaskJob, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdatePromoCodesTaskMetaData
 */
export class UpdatePromoCodesTaskMetaData extends Message<UpdatePromoCodesTaskMetaData> {
  /**
   * @generated from field: repeated string job_ids = 1;
   */
  jobIds: string[] = [];

  constructor(data?: PartialMessage<UpdatePromoCodesTaskMetaData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdatePromoCodesTaskMetaData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "job_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePromoCodesTaskMetaData {
    return new UpdatePromoCodesTaskMetaData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePromoCodesTaskMetaData {
    return new UpdatePromoCodesTaskMetaData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePromoCodesTaskMetaData {
    return new UpdatePromoCodesTaskMetaData().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePromoCodesTaskMetaData | PlainMessage<UpdatePromoCodesTaskMetaData> | undefined, b: UpdatePromoCodesTaskMetaData | PlainMessage<UpdatePromoCodesTaskMetaData> | undefined): boolean {
    return proto3.util.equals(UpdatePromoCodesTaskMetaData, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditPromoCodeRequest
 */
export class EditPromoCodeRequest extends Message<EditPromoCodeRequest> {
  /**
   * @generated from field: int64 promo_code_id = 1;
   */
  promoCodeId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Int64Value affiliation_program_id = 2;
   */
  affiliationProgramId?: bigint;

  /**
   * @generated from oneof librarian.proto.EditPromoCodeRequest.discount
   */
  discount: {
    /**
     * @generated from field: google.protobuf.DoubleValue percentage = 3;
     */
    value: DoubleValue;
    case: "percentage";
  } | {
    /**
     * @generated from field: google.protobuf.DoubleValue fixed_amount = 4;
     */
    value: DoubleValue;
    case: "fixedAmount";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.EditPromoCodeRequest.min_requirements
   */
  minRequirements: {
    /**
     * @generated from field: google.protobuf.DoubleValue min_purchase_amount = 5;
     */
    value: DoubleValue;
    case: "minPurchaseAmount";
  } | {
    /**
     * @generated from field: google.protobuf.Int32Value min_quantity_of_items = 6;
     */
    value: Int32Value;
    case: "minQuantityOfItems";
  } | {
    /**
     * @generated from field: google.protobuf.BoolValue clear_min_requirements = 7;
     */
    value: BoolValue;
    case: "clearMinRequirements";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.EditPromoCodeRequest.start_date
   */
  startDate: {
    /**
     * @generated from field: google.protobuf.Timestamp start_date_value = 8;
     */
    value: Timestamp;
    case: "startDateValue";
  } | {
    /**
     * Clear start date = set start date to current date
     *
     * @generated from field: google.protobuf.BoolValue clear_start_date = 21;
     */
    value: BoolValue;
    case: "clearStartDate";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.EditPromoCodeRequest.expiration_date
   */
  expirationDate: {
    /**
     * @generated from field: google.protobuf.Timestamp expiration_date_value = 9;
     */
    value: Timestamp;
    case: "expirationDateValue";
  } | {
    /**
     * @generated from field: google.protobuf.BoolValue clear_expiration_date = 10;
     */
    value: BoolValue;
    case: "clearExpirationDate";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: google.protobuf.BoolValue limit_to_one_use_per_customer = 11;
   */
  limitToOneUsePerCustomer?: boolean;

  /**
   * @generated from oneof librarian.proto.EditPromoCodeRequest.total_uses_limit
   */
  totalUsesLimit: {
    /**
     * @generated from field: google.protobuf.Int32Value total_uses_limit_value = 12;
     */
    value: Int32Value;
    case: "totalUsesLimitValue";
  } | {
    /**
     * @generated from field: google.protobuf.BoolValue clear_total_uses_limit = 13;
     */
    value: BoolValue;
    case: "clearTotalUsesLimit";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.EditPromoCodeRequest.campaign
   */
  campaign: {
    /**
     * @generated from field: google.protobuf.Int64Value campaign_id = 14;
     */
    value: Int64Value;
    case: "campaignId";
  } | {
    /**
     * @generated from field: google.protobuf.BoolValue clear_campaign = 15;
     */
    value: BoolValue;
    case: "clearCampaign";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.EditPromoCodeRequest.entitled_products
   */
  entitledProducts: {
    /**
     * @generated from field: librarian.proto.EditPromoCodeRequest.EditPromoCodesRequestEntitledProductVariants entitled_product_variants_ids = 16;
     */
    value: EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants;
    case: "entitledProductVariantsIds";
  } | {
    /**
     * @generated from field: google.protobuf.BoolValue apply_to_any_product = 17;
     */
    value: BoolValue;
    case: "applyToAnyProduct";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.EditPromoCodeRequest.discount_code
   */
  discountCode: {
    /**
     * @generated from field: google.protobuf.StringValue auto_generated_influencer_name_suffix = 18;
     */
    value: StringValue;
    case: "autoGeneratedInfluencerNameSuffix";
  } | {
    /**
     * @generated from field: google.protobuf.StringValue auto_generated_code_suffix = 19;
     */
    value: StringValue;
    case: "autoGeneratedCodeSuffix";
  } | {
    /**
     * @generated from field: google.protobuf.StringValue manual_input = 20;
     */
    value: StringValue;
    case: "manualInput";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<EditPromoCodeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditPromoCodeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "promo_code_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "affiliation_program_id", kind: "message", T: Int64Value },
    { no: 3, name: "percentage", kind: "message", T: DoubleValue, oneof: "discount" },
    { no: 4, name: "fixed_amount", kind: "message", T: DoubleValue, oneof: "discount" },
    { no: 5, name: "min_purchase_amount", kind: "message", T: DoubleValue, oneof: "min_requirements" },
    { no: 6, name: "min_quantity_of_items", kind: "message", T: Int32Value, oneof: "min_requirements" },
    { no: 7, name: "clear_min_requirements", kind: "message", T: BoolValue, oneof: "min_requirements" },
    { no: 8, name: "start_date_value", kind: "message", T: Timestamp, oneof: "start_date" },
    { no: 21, name: "clear_start_date", kind: "message", T: BoolValue, oneof: "start_date" },
    { no: 9, name: "expiration_date_value", kind: "message", T: Timestamp, oneof: "expiration_date" },
    { no: 10, name: "clear_expiration_date", kind: "message", T: BoolValue, oneof: "expiration_date" },
    { no: 11, name: "limit_to_one_use_per_customer", kind: "message", T: BoolValue },
    { no: 12, name: "total_uses_limit_value", kind: "message", T: Int32Value, oneof: "total_uses_limit" },
    { no: 13, name: "clear_total_uses_limit", kind: "message", T: BoolValue, oneof: "total_uses_limit" },
    { no: 14, name: "campaign_id", kind: "message", T: Int64Value, oneof: "campaign" },
    { no: 15, name: "clear_campaign", kind: "message", T: BoolValue, oneof: "campaign" },
    { no: 16, name: "entitled_product_variants_ids", kind: "message", T: EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants, oneof: "entitled_products" },
    { no: 17, name: "apply_to_any_product", kind: "message", T: BoolValue, oneof: "entitled_products" },
    { no: 18, name: "auto_generated_influencer_name_suffix", kind: "message", T: StringValue, oneof: "discount_code" },
    { no: 19, name: "auto_generated_code_suffix", kind: "message", T: StringValue, oneof: "discount_code" },
    { no: 20, name: "manual_input", kind: "message", T: StringValue, oneof: "discount_code" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditPromoCodeRequest {
    return new EditPromoCodeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditPromoCodeRequest {
    return new EditPromoCodeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditPromoCodeRequest {
    return new EditPromoCodeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditPromoCodeRequest | PlainMessage<EditPromoCodeRequest> | undefined, b: EditPromoCodeRequest | PlainMessage<EditPromoCodeRequest> | undefined): boolean {
    return proto3.util.equals(EditPromoCodeRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditPromoCodeRequest.EditPromoCodesRequestEntitledProductVariants
 */
export class EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants extends Message<EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants> {
  /**
   * @generated from field: repeated int64 product_variants_ids = 1;
   */
  productVariantsIds: bigint[] = [];

  constructor(data?: PartialMessage<EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditPromoCodeRequest.EditPromoCodesRequestEntitledProductVariants";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_variants_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants {
    return new EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants {
    return new EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants {
    return new EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants().fromJsonString(jsonString, options);
  }

  static equals(a: EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants | PlainMessage<EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants> | undefined, b: EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants | PlainMessage<EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants> | undefined): boolean {
    return proto3.util.equals(EditPromoCodeRequest_EditPromoCodesRequestEntitledProductVariants, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditPromoCodeResponse
 */
export class EditPromoCodeResponse extends Message<EditPromoCodeResponse> {
  /**
   * @generated from field: librarian.proto.AffiliationPromoCodeWithInfoPb promo_code = 1;
   */
  promoCode?: AffiliationPromoCodeWithInfoPb;

  constructor(data?: PartialMessage<EditPromoCodeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditPromoCodeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "promo_code", kind: "message", T: AffiliationPromoCodeWithInfoPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditPromoCodeResponse {
    return new EditPromoCodeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditPromoCodeResponse {
    return new EditPromoCodeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditPromoCodeResponse {
    return new EditPromoCodeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EditPromoCodeResponse | PlainMessage<EditPromoCodeResponse> | undefined, b: EditPromoCodeResponse | PlainMessage<EditPromoCodeResponse> | undefined): boolean {
    return proto3.util.equals(EditPromoCodeResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.InitGetPromoCodesListsResponse
 */
export class InitGetPromoCodesListsResponse extends Message<InitGetPromoCodesListsResponse> {
  /**
   * @generated from field: repeated librarian.proto.CreatorCardSnippet influencers = 1;
   */
  influencers: CreatorCardSnippet[] = [];

  /**
   * @generated from field: repeated librarian.proto.PromoCodeCampaign campaigns = 2;
   */
  campaigns: PromoCodeCampaign[] = [];

  /**
   * @generated from field: repeated librarian.proto.EcommerceStorePb stores = 3;
   */
  stores: EcommerceStorePb[] = [];

  /**
   * @generated from field: repeated librarian.proto.Program programs = 4;
   */
  programs: Program[] = [];

  constructor(data?: PartialMessage<InitGetPromoCodesListsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InitGetPromoCodesListsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencers", kind: "message", T: CreatorCardSnippet, repeated: true },
    { no: 2, name: "campaigns", kind: "message", T: PromoCodeCampaign, repeated: true },
    { no: 3, name: "stores", kind: "message", T: EcommerceStorePb, repeated: true },
    { no: 4, name: "programs", kind: "message", T: Program, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitGetPromoCodesListsResponse {
    return new InitGetPromoCodesListsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitGetPromoCodesListsResponse {
    return new InitGetPromoCodesListsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitGetPromoCodesListsResponse {
    return new InitGetPromoCodesListsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitGetPromoCodesListsResponse | PlainMessage<InitGetPromoCodesListsResponse> | undefined, b: InitGetPromoCodesListsResponse | PlainMessage<InitGetPromoCodesListsResponse> | undefined): boolean {
    return proto3.util.equals(InitGetPromoCodesListsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ListAffiliatesRequest
 */
export class ListAffiliatesRequest extends Message<ListAffiliatesRequest> {
  /**
   * @generated from field: google.protobuf.StringValue name_prefix = 1;
   */
  namePrefix?: string;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<ListAffiliatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ListAffiliatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name_prefix", kind: "message", T: StringValue },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAffiliatesRequest {
    return new ListAffiliatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAffiliatesRequest {
    return new ListAffiliatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAffiliatesRequest {
    return new ListAffiliatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAffiliatesRequest | PlainMessage<ListAffiliatesRequest> | undefined, b: ListAffiliatesRequest | PlainMessage<ListAffiliatesRequest> | undefined): boolean {
    return proto3.util.equals(ListAffiliatesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ListAffiliatesResponse
 */
export class ListAffiliatesResponse extends Message<ListAffiliatesResponse> {
  /**
   * @generated from field: repeated librarian.proto.CreatorCardSnippet affiliates = 1;
   */
  affiliates: CreatorCardSnippet[] = [];

  /**
   * @generated from field: int64 total_hits = 2;
   */
  totalHits = protoInt64.zero;

  constructor(data?: PartialMessage<ListAffiliatesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ListAffiliatesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "affiliates", kind: "message", T: CreatorCardSnippet, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAffiliatesResponse {
    return new ListAffiliatesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAffiliatesResponse {
    return new ListAffiliatesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAffiliatesResponse {
    return new ListAffiliatesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAffiliatesResponse | PlainMessage<ListAffiliatesResponse> | undefined, b: ListAffiliatesResponse | PlainMessage<ListAffiliatesResponse> | undefined): boolean {
    return proto3.util.equals(ListAffiliatesResponse, a, b);
  }
}

