import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  computed,
} from '@angular/core';
import { Int64Value } from '@bufbuild/protobuf';
import { FrontendMiscClient, FrontendMiscClientProvider } from '@frontend2/api';
import { formatDate, isNotEmptyString } from '@frontend2/core';
import {
  Account,
  GetUserAccountResp,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { ImpersonationLink } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { RouteBlocComponent } from '@frontend2/ui';
import { CustomerBloc } from './customer.bloc';

@Component({
  selector: 'customer-details',
  templateUrl: 'customer-details.route.html',
  styleUrls: ['customer-details.route.scss'],
  providers: [CustomerBloc],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerDetailsRouteComponent extends RouteBlocComponent<
  Int64Value,
  GetUserAccountResp
> {
  constructor(
    routeViewBloc: CustomerBloc,
    @Inject(FrontendMiscClientProvider)
    private frontendMiscRpc: FrontendMiscClient,
  ) {
    super(routeViewBloc);
  }

  readonly customer = computed(() => this.viewState().account ?? new Account());

  readonly canImpersonate = computed(() => this.viewState().canImpersonate);

  readonly secondaryText = computed(
    () => this.customer().firstName + ' ' + this.customer().lastName,
  );

  readonly created = computed(() =>
    formatDate(this.customer().created?.toDate() ?? ''),
  );

  readonly company = computed(() => {
    if (Object.keys(this.customer().users).length === 0) {
      return '-';
    } else {
      const firstUsersCompany = this.customer().users[0].company;
      return isNotEmptyString(firstUsersCompany) ? firstUsersCompany : '-';
    }
  });

  readonly dashboardAccess = computed(() => this.customer().dashboardAccess);

  readonly ssoProviderName = computed(
    () => this.customer().ssoProviderName || '-',
  );

  async impersonate(): Promise<void> {
    const link = await this.frontendMiscRpc.generateImpersonationLink(
      ImpersonationLink.fromJson({
        customerEmail: this.customer().email,
        // This should NOT correspond to `API_HOST`
        // we always want account management
        // to impersonate user on the PROD app, not STAGING
        host: 'https://api.lefty.io',
      }),
    );
    window.open(link.value, 'Impersonate' + this.customer().firstName);
  }

  async refreshView(): Promise<void> {
    await this.routeViewBloc.refresh();
  }
}
