import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { leftyIsAdminGuard } from '@frontend2/api';
import { UiModule } from '@frontend2/ui';
import { CommonComponentsModule } from '../common/components/common-components.module';
import { MEMBERS, TEAM_ID_KEY } from '../constants';
import { WorkspaceItemComponent } from './workspace-item/workspace-item.component';
import { WorkspacesListComponent } from './workspaces-list/workspaces-list.component';
import { FlandersWorkspacesRouteComponent } from './workspaces.route';

const routes: Routes = [
  {
    path: '',
    component: FlandersWorkspacesRouteComponent,
    data: {
      cacheComponent: true,
    },
  },
  {
    path: `:${TEAM_ID_KEY}`,
    redirectTo: `:${TEAM_ID_KEY}/${MEMBERS}`,
    pathMatch: 'full',
  },
  {
    path: `:${TEAM_ID_KEY}/${MEMBERS}`,
    loadChildren: () =>
      import('./workspace-details-route/workspace-details.module').then(
        (m) => m.WorkspaceDetailsModule,
      ),
    canActivate: [leftyIsAdminGuard],
  },
];

@NgModule({
  imports: [
    UiModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    CommonComponentsModule,
  ],
  declarations: [
    FlandersWorkspacesRouteComponent,
    WorkspaceItemComponent,
    WorkspacesListComponent,
  ],
})
export class WorkspacesModule {}
