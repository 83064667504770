import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Int64Value } from '@bufbuild/protobuf';
import { ConnectError } from '@connectrpc/connect';
import {
  LeftyAuthBloc,
  LibrarianAdminClient,
  LibrarianAdminClientProvider,
} from '@frontend2/api';
import { isGhostTeamUser, isNotEmptyString } from '@frontend2/core';
import {
  Account,
  CreateUserRequest,
  EditUserRequest,
  TeamUser,
} from '@frontend2/proto/librarian/proto/admin_pb';
import {
  SignupRequest,
  SsoProvidersList_SsoProvider,
} from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import {
  DialogBase,
  ItemRenderer,
  SsoProvidersCache,
  ToastManager,
} from '@frontend2/ui';
import { Subject } from 'rxjs';
import { CreateOrEditUserService } from './create-or-edit-customer.service';

@Component({
  selector: 'create-or-edit-user-dialog',
  templateUrl: 'create-or-edit-customer-dialog.component.html',
  styleUrls: ['create-or-edit-customer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateOrEditCustomerDialogComponent extends DialogBase {
  constructor(
    @Inject(LibrarianAdminClientProvider)
    private librarian: LibrarianAdminClient,
    private _auth: LeftyAuthBloc,
    private createOrEditUserService: CreateOrEditUserService,
    private ssoProvidersCache: SsoProvidersCache,
    private toastManager: ToastManager,
  ) {
    super();

    this.disposer.add(this.success$);
  }

  adminAccess = false;

  loading = false;

  cacheLoading = false;

  _selectedProvider = new SsoProvidersList_SsoProvider();

  readonly providersList = this.ssoProvidersCache.providersList;

  model = new CreateUserRequest();

  signupReq = new SignupRequest();

  editPassword = false;

  currentUser = new Account();

  _ssoAccess = false;

  @ViewChild('userForm')
  userForm?: NgForm;

  @Output()
  readonly success$ = new Subject<void>();

  private async _loadData(): Promise<void> {
    await this.ssoProvidersCache.load();
  }

  private _openWith(user: Account): void {
    this.currentUser = user;
    const signup = new SignupRequest({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.users.length > 0 ? user.users[0].company : undefined,
      dashboardAccess: user.dashboardAccess,
    });

    this.adminAccess = user.adminAccess;

    if (isNotEmptyString(user.ssoProviderName)) {
      this._ssoAccess = true;
      this.selectedProvider =
        this.providersList().find(
          (p) => p.providerName === user.ssoProviderName,
        ) ?? new SsoProvidersList_SsoProvider();
    }

    this.signupReq = signup;
  }

  async openWith(user?: Account | TeamUser): Promise<void> {
    this.resetForm();
    this.setState(() => (this.cacheLoading = true));
    this._loadData().then(async () => {
      if (user instanceof Account) {
        this._openWith(user);
      }
      if (user instanceof TeamUser && !isGhostTeamUser(user)) {
        this.setState(() => (this.loading = true));
        this._openWith(
          await this.librarian.getUserAccountByIdAPI(
            new Int64Value({ value: user.userAccountId }),
          ),
        );
        this.setState(() => (this.loading = false));
      }

      this.setState(() => (this.cacheLoading = false));
    });
    super.open();
  }

  resetForm(): void {
    this._ssoAccess = false;
    this.selectedProvider = new SsoProvidersList_SsoProvider();
    this.signupReq = new SignupRequest();
    this.adminAccess = false;
  }

  onSubmit(): void {
    if (this.isFormValid === false || this.loading === true) {
      return;
    }

    if (this.ssoAccess && !this.editPassword) {
      this.signupReq.ssoConfigFileName = this.selectedProvider.configFileName;
    }

    if (this.isSuperAdmin) {
      this.signupReq.adminAccess = this.adminAccess;
    }

    this.model.signup = this.signupReq;
    this._save();
  }

  private async _save(): Promise<void> {
    try {
      this.loading = true;
      this.changeDetection.markForCheck();
      if (this.edit) {
        const editUserRequest = new EditUserRequest();
        editUserRequest.signup = this.model.signup;
        editUserRequest.userAccountId = this.currentUser.userAccountId;
        await this.createOrEditUserService.edit(editUserRequest);
      } else {
        await this.createOrEditUserService.create(this.model);
      }
      this.success$.next();
      this.close();
    } catch (e: unknown) {
      if (e instanceof ConnectError) {
        this.toastManager.showError(e.message);
      }
    } finally {
      this.loading = false;
      this.changeDetection.markForCheck();
    }
  }

  providerRenderer: ItemRenderer<SsoProvidersList_SsoProvider> = (provider) =>
    provider.providerName;

  get isFormValid(): boolean {
    return this.userForm?.form?.valid === true;
  }

  get isSuperAdmin(): boolean {
    return this._auth.isSuperAdmin;
  }

  get showPasswordInput(): boolean {
    return !this.ssoAccess && (!this.edit || (this.edit && this.editPassword));
  }

  get title(): string {
    return this.edit ? `Edit "${this.signupReq.email}" user` : 'Create user';
  }

  get edit(): boolean {
    return this.currentUser.userAccountId !== BigInt(0);
  }

  get ssoAccess(): boolean {
    return this._ssoAccess;
  }

  set ssoAccess(value: boolean) {
    if (value === this._ssoAccess) {
      return;
    }
    if (value === false) {
      this.selectedProvider = new SsoProvidersList_SsoProvider();
    } else {
      this.editPassword = false;
      this.signupReq.password = '';
      this.selectedProvider =
        this.providersList().length > 0
          ? this.providersList()[0]
          : new SsoProvidersList_SsoProvider();
    }
    this._ssoAccess = value;
  }

  get selectedProvider(): SsoProvidersList_SsoProvider {
    return this._selectedProvider;
  }

  set selectedProvider(newValue: SsoProvidersList_SsoProvider) {
    this._selectedProvider = newValue;
  }
}
