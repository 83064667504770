import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import {
  LeftyFormat,
  copyToClipboard,
  formatDate,
  isGhostTeam,
  subscriptionToReadable,
} from '@frontend2/core';
import {
  TeamUser,
  TeamsResponse_TeamLight,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { LeftyComponent, ToastManager } from '@frontend2/ui';
import { Subject } from 'rxjs';

@Component({
  selector: 'workspace-list-item',
  templateUrl: 'workspace-item.component.html',
  styleUrls: ['workspace-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceItemComponent extends LeftyComponent {
  constructor(private toastManager: ToastManager) {
    super();
  }

  @Input()
  team = new TeamsResponse_TeamLight();

  @Output()
  readonly workspaceToEdit$ = new Subject<TeamUser>();

  teamIsGhost(team: TeamsResponse_TeamLight): boolean {
    return isGhostTeam(team);
  }

  stayInPage(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  get teamTitle(): string {
    return `${this.team.id} - ${this.team.name}`;
  }

  get membersCount(): string {
    return LeftyFormat.profiles(this.team.membersCount, { showZero: true });
  }

  get creationDate(): string {
    return this.team.created?.toDate()
      ? formatDate(this.team.created?.toDate())
      : 'Unknown';
  }

  get ownerEmail(): string {
    return this.team.owner
      ? `${this.team.owner.userId} - ${this.team.owner.email}`
      : 'Unknown';
  }

  get teamSubscription(): string {
    return this.team.subscription
      ? subscriptionToReadable(this.team.subscription.subscriptionType)
      : 'Unknown';
  }

  get company(): string {
    return this.team.owner?.company ?? '';
  }

  editWorkspace(): void {
    this.workspaceToEdit$.next(new TeamUser({ teamId: this.team.id }));
  }

  async copyEmail(e: MouseEvent): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    if (this.team.owner?.email) {
      await copyToClipboard(this.team.owner?.email);
      this.toastManager.showSuccess(
        '"' + this.team.owner?.email + '" copied to clipboard',
      );
    }
  }
}
