import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { API_HOST, GrpcModule, LeftyAuthModule } from '@frontend2/api';
import { LeftyErrorHandlerModule, UiModule } from '@frontend2/ui';
import { environment } from '../environments/environment';
import { AccountMenuComponent } from './account-menu.component';
import { FlandersAppComponent } from './app.component';
import { AppRouterModule } from './app.routes';
import { CustomerDetailsModule } from './customers-route/customer-details-route/customer-details.module';
import { CustomersModule } from './customers-route/customers.module';
import { FlandersLoginRouteComponent } from './login-route/login.route';
import { WorkspacesModule } from './workspaces-route/workspaces.module';

@NgModule({
  declarations: [
    FlandersAppComponent,
    FlandersLoginRouteComponent,
    AccountMenuComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    LeftyErrorHandlerModule,
    AppRouterModule,
    GrpcModule,
    LeftyAuthModule,
    CustomersModule,
    CustomerDetailsModule,
    WorkspacesModule,
  ],
  providers: [
    { provide: API_HOST, useValue: environment.apiHost },
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
  ],
  exports: [RouterModule],
  bootstrap: [FlandersAppComponent],
})
export class AppModule {}
