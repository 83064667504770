<div
  class="left-panel"
  [style.max-width.px]="leftPanelWidth"
  [style.min-width.px]="leftPanelWidth"
  [style.width.px]="leftPanelWidth"
  [class.hidden]="!leftPanelVisible"
  [class.sidebar-layout-hide]="sideBarShow === false"
  *ngIf="withLeftPanel"
>
  <ng-template [ngIf]="sideBarShow">
    <ng-content select="[left-panel]"></ng-content>
  </ng-template>
  <button
    class="arrow-btn small icon"
    *ngIf="leftPaneltoggle && sideBarShow"
    (click)="sideBarToggle()"
  >
    <lefty-icon
      class="arrow-icon"
      [class.rotate]="sideBarShow"
      icon="arrow_right"
    ></lefty-icon>
  </button>
</div>
<button
  class="arrow-btn above small icon"
  *ngIf="leftPaneltoggle && sideBarShow === false"
  (click)="sideBarToggle()"
  [class.out-of-panel]="sideBarShow === false"
>
  <lefty-icon
    class="arrow-icon"
    [class.rotate]="sideBarShow"
    icon="arrow_right"
  ></lefty-icon>
</button>

<div
  class="layout-content"
  [style.width]="contentWidth"
>
  <ng-content></ng-content>
</div>

<div
  class="right-panel"
  [style.max-width.px]="rightPanelWidth"
  [style.min-width.px]="rightPanelWidth"
  [style.width.px]="rightPanelWidth"
  [class.hidden]="!rightPanelVisible"
  *ngIf="withRightPanel"
>
  <ng-content select="[right-panel]"></ng-content>
</div>
